<template>
  <li :class="['checkout-summary-price', { 'is-total': total }]" role="presentation">
    <div class="text">{{ text }}</div>
    <i18n-n tag="div" :value="price.number" :format="{ key: 'currency', currency }" class="price">
      <template #currency="slotProps"><span class="currency d-none">{{ slotProps.currency }}</span></template>
      <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
      <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
      <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
      <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
    </i18n-n>
  </li>
</template>

<script>

export default {
  name: 'CheckoutSummaryPrice',
  props: {
    text: {
      type: String,
      default: ''
    },
    amount: {
      type: [String, Number],
      default: 0
    },
    currency: {
      type: String,
      default: 'CHF'
    },
    total: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    price () {
      return this.createPrice(this.amount)
    }
  },
  methods: {
    createPrice (value) {
      const number = Number(value)

      return {
        isValid: !isNaN(number) && number > 0,
        original: value,
        number
      }
    }
  }
}
</script>

<style lang="scss">
$checkoutsummaryprice-total-margin-y: $spacer * 0.5 !default;
$checkoutsummaryprice-total-border: set-nth(map-get($borders, 'bold'), 3, $hr-border-color) !default;
$checkoutsummaryprice-total-font-weight: $font-weight-bold !default;

.checkout-summary-price {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.is-total {
    margin-top: $checkoutsummaryprice-total-margin-y;
    padding-top: $checkoutsummaryprice-total-margin-y;
    border-top: $checkoutsummaryprice-total-border;
    font-weight: $checkoutsummaryprice-total-font-weight;
  }
}
</style>
