<template>
  <div class="basket">
    <loader v-if="is.initial" variant="primary" />
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-4">
      <b-col v-for="item in basket.items" :key="item.product.id" class="pb-4">
        <div class="card teaser img-type-product">
          <c-teaser :imgSrc="item.product.image" :href="generateUrl(item)">
            <h3><strong>{{ item.product.name }}</strong></h3>
            <p v-if="item.product.portionierung">{{ item.product.portionierung }}</p>
            <template>
              <c-wish-list-button :productId="item.product.id" :wishListButtonText="$T('WishListButtonText')" :wishListButtonTextRemoveText="$T('WishListRemoveButtonText')" />
            </template>
          </c-teaser>
          <div class="card-footer"></div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import { ASIDE_COLUMN_WIDTHS } from '@/assets/js/config/client'
import Loader from '@/components/Loader'

export default {
  name: 'Basket',
  components: {
    Loader
  },
  props: {
    productBaseUrl: {
      type: String,
      default: ''
    },
    marketingProductBaseUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    is () {
      return this.$store.getters['wishlistcart/is']
    },
    basket () {
      return this.$store.getters['wishlistcart/get']
    },
    wishListItemIds () {
      return this.$store.getters['wishlistcart/get'].containedItemExternalIds
    }
  },
  data () {
    return {
      contentCols: ASIDE_COLUMN_WIDTHS
    }
  },
  methods: {
    deleteBasket () {
      this.$store.dispatch('wishlistcart/delete')
    },
    deleteItem (item) {
      this.$store.dispatch('wishlistcart/deleteItem', { itemId: item.basketItemId, omitLastAction: true })
    },
    generateUrl (item) {
      let baseUrl = ''
      switch (item.productType) {
        case 'Product':
          baseUrl = this.productBaseUrl
          break
        case 'MarketingProduct':
          baseUrl = this.marketingProductBaseUrl
          break
      }
      return `${baseUrl}/${item.product.urlSlug}`
    }
  },
  created () {
    this.$store.dispatch('wishlistcart/get')

    window.addEventListener('beforeprint', this.beforePrint)
    window.addEventListener('afterprint', this.afterPrint)
  },
  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrint)
    window.removeEventListener('afterprint', this.afterPrint)
  }
}
</script>

<style lang="scss">
// mobile

$basket-emptymessage-icon-gap: $spacer !default;
$basket-emptymessage-content-text-align: center !default;

$basket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$basket-items-footer-actions-gap-x: $spacer * 2 !default;

// tablet

$basket-tablet: $mobile-breakpoint !default;

$basket-tablet-emptymessage-icon-gap: 0 !default;
$basket-tablet-emptymessage-content-text-align: inherit !default;

$basket-tablet-items-grid-columns: [start] auto 4fr 1fr 1fr 1fr [end] !default;

$basket-tablet-items-header-font-size: $font-size-sm !default;
$basket-tablet-items-header-color: $dark !default;
$basket-tablet-items-header-line-height: 1.1 !default;

// desktop

$basket-desktop: $desktop-breakpoint !default;

.basket {
  @include component('y');

  .basket-emptymessage {
    @include component('y');

    .emptymessage-icon {
      margin-bottom: $basket-emptymessage-icon-gap;
    }

    .emptymessage-content {
      text-align: $basket-emptymessage-content-text-align;
    }
  }

  @include media-breakpoint-up($basket-tablet) {
    .basket-emptymessage {
      .emptymessage-icon {
        margin-bottom: $basket-tablet-emptymessage-icon-gap;
      }

      .emptymessage-content {
        text-align: $basket-tablet-emptymessage-content-text-align;
      }
    }
  }

  .packshot {
    position: relative;

    &-image {
      width: 100%;
      margin-bottom: 0;
    }

    .item-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      background-color: rgba($light, 0.50)
    }
  }
}

@media print {
  .basket-print-mode {
    #app {

      >header,
      >footer {
        display: none !important;
      }

      >main {
        .basket~* {
          display: none !important;
        }
      }
    }
  }
}
</style>
