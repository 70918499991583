<template>
  <c-leaf class="product" tag="article">
    <header class="product-header">
      <slot name="title" />
    </header>

    <b-row tag="section">
      <b-col :[$root.mobile.up.key]="5">
        <c-carousel class="product-images" :autoplay="false">
          <slot name="images">
            <c-placeholder-image ratio="4x3" size="75" />
          </slot>
        </c-carousel>
      </b-col>

      <b-col :[$root.mobile.up.key]="7">
        <div class="product-description d-flex flex-column justify-content-end">
          <slot name="description" />
        </div>

        <div class="product-tags">
          <slot name="tags" />
        </div>
      </b-col>
    </b-row>

    <slot />
  </c-leaf>
</template>

<script>
export default {
  name: 'Product'
}
</script>

<style lang="scss">
$product-header-margin-bottom: $spacer !default;

$product-images-max-width: none !default;
$product-images-img-padding-y: $component-padding-x / 4 * 3 !default;
$product-images-img-padding-x: $component-padding-x !default;
$product-images-placeholder-size: 80% !default;
$product-images-placeholder-color: $gray-300 !default;

$product-tags-gap: $spacer * 0.3 !default;

$product-mobile-breakpoint: $mobile-breakpoint !default;

$product-images-mobile-max-width: 320px !default;

.product {
  .product-header {
    margin-bottom: $product-header-margin-bottom;

    * {
      margin: 0;
    }
  }

  .product-images {
    margin: 0 auto;
    max-width: $product-images-max-width;

    img {
      margin: 0;
      padding: $product-images-img-padding-y $product-images-img-padding-x;
    }

    .image-placeholder {
      width: auto;
      height: $product-images-placeholder-size;
      color: $product-images-placeholder-color;
    }
  }

  .product-description {
    position: relative;
    min-height: 100%;

    >.product-basketbutton {
      width: 100%;
    }
  }

  .product-tags {
    display: flex;
    flex-wrap: wrap;
    margin: $product-tags-gap * -0.5;

    .badge {
      margin: $product-tags-gap * 0.5;
    }
  }

  @include media-breakpoint-down($product-mobile-breakpoint) {
    .product-images {
      max-width: $product-images-mobile-max-width;
    }
  }
}
</style>
