<template>
  <div class="search-result-products">
    <b-row :cols="contentCols.xs" :cols-sm="contentCols.sm" :cols-md="contentCols.md" :cols-lg="contentCols.lg" :cols-xl="contentCols.xl">
      <b-col v-for="result in results" :key="result.id">
        <c-teaser :noImg="result.noImg" :tagText="result.event.type" :tagVariant="result.event.typeVariant" :languageText="result.event.language ? $T(`Language${result.event.language}`) : null" :stateText="result.event.state" :stateVariant="result.event.stateVariant" :href="result.href" :imgType="result.imgType" :imgSrc="result.imgSrc" :imgAlt="result.imgAlt" :hideDefaultFooter="hideDefaultFooter">
          <h3>{{ result.title }}</h3>
          <p v-if="result.product.portioning">{{ result.product.portioning }}</p>
          <p v-if="result.text">{{ result.text }}</p>
          <slot name="resultadditionalinfo" v-bind="{ resultItem: result }" />

          <p v-if="result.event.date && result.event.location">
            <strong>
              <timeago :datetime="result.event.date" :converterOptions="{ format: 'P', forceDateFormat: true }" />
            </strong>, {{ result.event.location }}
          </p>

          <template v-if="result.alternativeEvents.length > 0">
            <p v-if="!result.showAlternativeEvents">
              <b-link @click.stop="result.showAlternativeEvents = true">{{ $T('AlternativeEventsButton') }}</b-link>
            </p>

            <template v-else>
              <p v-for="event in result.alternativeEvents" :key="event.id">
                <a :href="event.url"><strong>
                    <timeago :datetime="event.date" :converterOptions="{ format: 'P', forceDateFormat: true }" />
                  </strong>, {{ event.location }}</a>
              </p>
            </template>
          </template>
          <template v-if="showPackshotBasketButton">
            <c-packshot-basket-button :productId="result.event.id" :basketButtonText="$T('PackshotBasketButtonText')" :basketButtonRemoveText="$T('PackshotBasketRemoveButtonText')" />
          </template>
          <template v-if="showWishListButton">
            <c-wish-list-button :productId="result.event.id" :wishListButtonText="$T('WishListButtonText')" :wishListButtonTextRemoveText="$T('WishListRemoveButtonText')" />
          </template>
          <template #teaserfooter v-if="isLoggedIn">
            <!-- TODO: Get correct stockAmount, Currency(could be hardcoded) and Price -->
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <c-stock-status :stockAmount="5" :showText="false"></c-stock-status>
                <div v-if="result.originalItem.price">
                  <nobr class="mr-2">
                    CHF {{ result.originalItem.price.toFixed(2).replace(".00", ".–") }}
                  </nobr>
                </div>
              </div>
              <!-- TODO: Readonly = user location assigned, v-if= stockamount > 0 -->
              <c-product-basketbutton :product-id="result.event.id" :product-type="result.imgType" :quantityinfo="{
                min: 1,
                max: 1000,
                step: 1,
                initialStep: 1
              }" :disabled="false" :customAmount="$T('CustomAmountInDropdown')" :read-only="false" v-if="true">
                <template #disabledcontent>
                  <slot name="disabledbasketbuttoncontent" />
                </template>
                <b-icon icon="b-bag-fill"></b-icon>
              </c-product-basketbutton>
              <div v-else class="flex-grow-1 text-right">
                <!-- TODO: Behaviour for notifications, maybe new component -->
                <b-button class="basketbutton">
                  <b-icon icon="b-bell-fill"></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </c-teaser>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ASIDE_COLUMN_WIDTHS } from '@/assets/js/config/client'

export default {
  name: 'Search.ResultstypeProduct',
  props: {
    resultType: {
      type: String
    },
    results: {
      type: Array,
      default: () => ([])
    },
    showPackshotBasketButton: {
      type: Boolean,
      default: false
    },
    showWishListButton: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    priceWithCurrency: {
      type: String,
      default: ''
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contentCols: ASIDE_COLUMN_WIDTHS
    }
  }
}
</script>

<style lang="scss">
.search-result-products {}
</style>
