<template>
  <div class="basket-item">
    <div class="area-separator" />

    <div class="area-image">
      <a :href="productUrl">
        <img v-if="basketLineItem.product.image" :src="basketLineItem.product.image" :alt="basketLineItem.product.name" />
        <c-placeholder-image v-else ratio="4x3" size="100" />
      </a>
    </div>

    <div v-if="$root.isMobile" class="area-actions">
      <div class="item-actions">
        <b-link @click="configureItem"><b-icon icon="pen" /></b-link>
        <b-link @click="deleteItem"><b-icon icon="trash" /></b-link>
      </div>
    </div>

    <div class="area-description">
      <p><a :href="productUrl"><span class="item-name">{{ basketLineItem.product.model }}</span> {{ basketLineItem.product.name }}</a></p>
    </div>

    <template v-if="$root.isMobile">
      <div v-if="basketLineItem.configuration.additionalConfigurations.dateStart && basketLineItem.configuration.additionalConfigurations.dateEnd" class="area-additional-configs">
        <span>{{ $t(`${tPath}.deliverytimeslot`) }} {{ basketLineItem.configuration.additionalConfigurations.dateStart | formatDate }} - {{ basketLineItem.configuration.additionalConfigurations.dateEnd | formatDate }}</span>
      </div>
      <div v-if="$root.isMobile" class="area-configuration">
        <p class="item-configuration">
          <span v-if="basketLineItem.product.bundleCount">{{ $t(`${tPath}.bundlecount`, { count: basketLineItem.product.bundleCount }) }}</span>
          <span>{{ $t(`${tPath}.articlenumber`) }} {{ basketLineItem.product.articleNumber ? basketLineItem.product.articleNumber : basketLineItem.product.id }}</span>
        </p>
      </div>
    </template>

    <div class="area-quantity">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.quantity`) }}</b-col>
        <b-col cols="auto">
          <control-spinbutton v-if="module.isEditable" :id="_uid + 'quantity'" v-model="itemQuantity" size="sm" class="mb-0" @input="updateItem" />

          <span v-else>{{ itemQuantity }}</span>
        </b-col>
      </b-row>

      <template v-else>
        <control-spinbutton v-if="module.isEditable" :id="_uid + 'quantity'" v-model="itemQuantity" size="sm" class="mb-0" :max="Number.MAX_SAFE_INTEGER" @input="updateItem" />

        <span v-else>{{ itemQuantity }}</span>
      </template>
    </div>

    <div class="area-price">
      <div class="product-price price-active inherit">
        <span class="currency">CHF</span> {{ basketLineItem.price.singlePrice.toFixed(2).replace(".00", ".–") }}
      </div>
    </div>

    <div class="area-totalprice">
      <div class="product-price price-active inherit is-sum">
        <span class="currency">CHF</span> {{ basketLineItem.price.totalPrice.toFixed(2).replace(".00", ".–") }}
      </div>
    </div>

    <template v-if="!$root.isMobile">
      <div v-if="basketLineItem.configuration.additionalConfigurations.dateStart && basketLineItem.configuration.additionalConfigurations.dateEnd" class="area-additional-configs">
        <b-icon :id="'popover-additional-config-' + basketLineItem.basketItemId" icon="b-info-circle"></b-icon>
        <b-popover :target="'popover-additional-config-' + basketLineItem.basketItemId" triggers="hover focus">
          <strong>{{ $t(`${tPath}.deliverytimeslot`) }}</strong><br>
          {{ basketLineItem.configuration.additionalConfigurations.dateStart | formatDate }} - {{ basketLineItem.configuration.additionalConfigurations.dateEnd | formatDate }}
        </b-popover>
      </div>
      <div class="area-configuration">
        <p class="item-configuration">
          <span v-if="basketLineItem.product.bundleCount">{{ $t(`${tPath}.bundlecount`, { count: basketLineItem.product.bundleCount }) }}</span>
          <span>{{ $t(`${tPath}.articlenumber`) }} {{ basketLineItem.product.articleNumber ? basketLineItem.product.articleNumber : basketLineItem.product.id }}</span>
        </p>

      </div>

      <div v-if="module.isEditable" class="area-actions">
        <div class="item-actions">
          <!-- <a href="#" @click="configureItem"><b-icon icon="pen"/></a> -->
          <a href="#" @click="deleteItem"><b-icon icon="trash" /></a>
        </div>
      </div>
    </template>

    <div class="area-separator" />
  </div>
</template>

<script>
import { COMPONENT_BASKET_MODULES } from '@/constants'

import ControlSpinbutton from '@/components/form/ControlSpinbutton'

const BASKET_MODULES = Object.values(COMPONENT_BASKET_MODULES)
const DEFAULT_MODULENAME = (BASKET_MODULES.find(m => m.isDefault) || BASKET_MODULES[0]).name
const ALLOWED_MODULE_NAMES = BASKET_MODULES.map(m => m.name)

export default {
  name: 'BasketItem',
  components: {
    ControlSpinbutton
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME,
      validator: value => ALLOWED_MODULE_NAMES.includes(value)
    },
    basketLineItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemQuantity: this.basketLineItem.quantity,
      productUrl: `${this.$store.getters['gui/getBaseUrl'](this.basketLineItem.productType.toLowerCase())}${this.basketLineItem.product.urlSlug}`
    }
  },
  computed: {
    module () {
      return BASKET_MODULES.find(m => m.name === this.moduleName)
    }
  },
  methods: {
    updateItem () {
      console.log(this.basketLineItem.quantity)
      this.$emit('basketitem:update', { id: this.basketLineItem.basketItemId, quantity: this.itemQuantity })
    },
    configureItem () {
      // TODO: define action
    },
    deleteItem () {
      this.$emit('basketitem:delete', { id: this.basketLineItem.basketItemId })
    }
  }
}
</script>

<style lang="scss">
// mobile

$basketitem-separator-gap-y: $spacer * 0.8 !default;
$basketitem-separator-border: map-get($borders, 'bold') !default;

$basketitem-image-width: 120px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $gray-300 !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

$basketitem-actions-gap-y: $spacer * 1 !default;
$basketitem-actions-gap-x: $basketitem-actions-gap-y !default;
$basketitem-actions-font-size: $font-size-base * 1.25 !default;

// tablet

$basketitem-tablet: $mobile-breakpoint !default;

$basketitem-tablet-image-width: 100px !default;

$basketitem-tablet-actions-gap-y: $spacer * 0.4 !default;
$basketitem-tablet-actions-gap-x: $spacer * 1.5 !default;
$basketitem-tablet-actions-font-size: 1rem !default;

// price
$productprice-active-font-size: 1.4em !default;
$productprice-active-font-weight: $font-weight-normal !default;
$productprice-active-color: $gray-700 !default;

$productprice-active-currency-font-size: 0.7em !default;
$productprice-active-fraction-font-size: inherit !default;

$productprice-active-promotional-color: $red !default;

$productprice-original-gap: $spacer * 0.5 !default;
$productprice-original-font-size: $productprice-active-font-size !default;
$productprice-original-font-weight: $productprice-active-font-weight !default;
$productprice-original-color: $productprice-active-color !default;
$productprice-original-linethrouth: set-nth(map-get($borders, 'base'), 3, rgba($productprice-original-color, 0.85)) !default;
$productprice-original-linethrouth-glow: 0 1px 2px 0 rgba($productprice-original-color, 0.85) !default;

$productprice-original-currency-font-size: $productprice-active-currency-font-size !default;
$productprice-original-fraction-font-size: $productprice-active-fraction-font-size !default;

$productprice-vatinfo-font-size: $font-size-sm !default;
$productprice-vatinfo-color: $gray-600 !default;

$productprice-sum-font-weight: $font-weight-bold !default;

.basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;
      }

      .area-image {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          img,
          .placeholder-image {
            display: block;
            width: $basketitem-image-width;
            max-width: none;

            .bi-image-placeholder {
              display: block;
              margin: auto;
              width: $basketitem-img-fallback-size;
              height: $basketitem-img-fallback-size;
              color: $basketitem-img-fallback-color;
            }
          }
        }
      }

      .area-description {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          .item-name {
            font-weight: $basketitem-description-name-font-weight;
            text-transform: $basketitem-description-name-text-transform;
          }
        }
      }

      .area-additional-configs {
        grid-column: start/5;
      }

      .area-configuration {
        .item-configuration {
          margin: ($basketitem-configuration-gap-y * -0.5) ($basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }
      }

      .area-actions {
        .item-actions {
          display: flex;
          flex-direction: column-reverse;
          margin: ($basketitem-actions-gap-y * -0.5) ($basketitem-actions-gap-x * -0.5);

          a {
            display: block;
            padding: ($basketitem-actions-gap-y * 0.5) ($basketitem-actions-gap-x * 0.5);
            font-size: $basketitem-actions-font-size;
          }
        }
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      +.basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }

      @include media-breakpoint-up($basketitem-tablet) {
        .area-image {
          a {

            img,
            .placeholder-image {
              margin: 0;
              width: $basketitem-tablet-image-width;
            }
          }
        }

        .area-price,
        .area-totalprice {
          white-space: nowrap;
        }

        .area-actions {
          .item-actions {
            flex-direction: row;
            margin: ($basketitem-tablet-actions-gap-y * -0.5) ($basketitem-tablet-actions-gap-x * -0.5);

            a {
              padding: ($basketitem-tablet-actions-gap-y * 0.5) ($basketitem-tablet-actions-gap-x * 0.5);
              font-size: $basketitem-tablet-actions-font-size;
            }
          }
        }

        .area-additional-configs {
          grid-column: 2 / span 5;
        }
      }
    }
  }
}

.product-price {
  &.price-active {
    display: block;
    font-size: $productprice-active-font-size;
    font-weight: $productprice-active-font-weight;
    color: $productprice-active-color;

    .currency {
      font-size: $productprice-active-currency-font-size;
    }

    &.is-promotional {
      color: $productprice-active-promotional-color;
    }
  }

  &.inherit {

    &.price-active {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    &.price-active {
      &.is-promotional {
        color: $productprice-active-promotional-color;
      }
    }
  }

  &.is-sum {

    &.price-active {
      font-weight: $productprice-sum-font-weight;
    }
  }
}
</style>
