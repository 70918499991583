import Vue from 'vue'
import Vuex from 'vuex'

import configuration from './modules/configuration'
import gui from './modules/gui'
import validators from './modules/validators'

import globalsearch from './modules/globalsearch'
import search from './modules/search'

import profile from './modules/profile'

import services from './modules/services'

import locationaddresses from './modules/location-addresses'
import locations from './modules/locations'

import useraddresses from './modules/user-addresses'
import usereducations from './modules/user-educations'
import userlocations from './modules/user-locations'
import userprofessions from './modules/user-professions'
import userservices from './modules/user-services'
import users from './modules/users'

import checkout from './modules/checkout'
import packshotlistcart from './modules/packshotlistcart'
import shoppingcart from './modules/shoppingcart'
import wishlistcart from './modules/wishlistcart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    profile,
    validators,

    search,
    globalsearch,

    services,

    locations,
    locationaddresses,

    users,
    useraddresses,
    userprofessions,
    userservices,
    usereducations,
    userlocations,

    shoppingcart,
    packshotlistcart,
    wishlistcart,
    checkout
  }
})
