import Vue from 'vue'

const COMPONENTS = {
  // Global
  GTag: () => import('@/components/GTag'),

  // Layout
  NavMeta: () => import('@/components/layout/NavigationMeta'),
  NavMain: () => import('@/components/layout/NavigationMain'),
  NavMainItem: () => import('@/components/layout/NavigationMainLevelItem'),
  NavBreadcrumb: () => import(/* webpackChunkName: "component-layout" */ '@/components/layout/NavigationBreadcrumb'),
  NavBreadcrumbItem: () => import(/* webpackChunkName: "component-layout" */ '@/components/layout/NavigationBreadcrumbItem'),
  NavFooter: () => import('@/components/layout/NavigationFooter'),
  NavFooterItem: () => import('@/components/layout/NavigationFooterItem'),
  NavBacklink: () => import('@/components/layout/NavigationBacklink'),
  HeaderBasket: () => import('@/components/layout/HeaderBasket'),
  HeaderPackShotBasket: () => import('@/components/layout/HeaderPackShotBasket'),
  HeaderSearch: () => import('@/components/layout/HeaderSearch'),
  HeaderWishList: () => import('@/components/layout/HeaderWishList'),
  ComFooter: () => import('@/components/layout/CommonFooter'),
  CLoader: () => import('@/components/Loader'),

  // Forms
  CustomForm: () => import('@/components/form/CustomForm'),
  ControlInput: () => import('@/components/form/ControlInput'),
  ControlSelect: () => import('@/components/form/ControlSelect'),
  ControlMultiselect: () => import('@/components/form/ControlMultiselect'),
  ControlTextarea: () => import('@/components/form/ControlTextarea'),
  ControlCheckbox: () => import('@/components/form/ControlCheckbox'),
  ControlRadio: () => import('@/components/form/ControlRadio'),
  ControlFile: () => import('@/components/form/ControlFile'),
  ControlFiles: () => import('@/components/form/ControlFiles'),
  ControlDatepicker: () => import('@/components/form/ControlDatepicker'),
  ControlSpinbutton: () => import('@/components/form/ControlSpinbutton'),
  ControlUnknown: () => import('@/components/form/ControlUnknown'),
  CInfoContainer: () => import(/* webpackChunkName "infocontainer" */ '@/components/InfoContainer'),

  // Extensions
  TransitionExpand: () => import(/* webpackChunkName: "extension-transitionexpand" */ '@/components/extension/TransitionExpand'),

  // Public Components
  CSubPageListing: () => import(/* webpackChunkName: "component-subpagelisting" */ '@/components/SubPageListingComponent'),

  CAnchorLink: () => import(/* webpackChunkName: "component-anchorlink" */ '@/components/AnchorLinkComponent'),

  CDualooIframe: () => import(/* webpackChunkName: "component-dualooiframe" */ '@/components/DualooIFrame'),

  CTrustBox: () => import(/* webpackChunkName: "component-trustbox" */ '@/components/TrustBox'),

  CTime: () => import(/* webpackChunkName: "component-time" */ '@/components/Time'),

  CPlaceholderImage: () => import(/* webpackChunkName: "component-placeholder-image" */ '@/components/PlaceholderImage'),

  CModalWindow: () => import(/* webpackChunkName: "component-modalwindow" */ '@/components/ModalWindow'),

  CVideo: () => import(/* webpackChunkName: "component-video" */ '@/components/Video'),

  CLeaf: () => import(/* webpackChunkName: "component-leaf" */ '@/components/Leaf'),

  CCarousel: () => import(/* webpackChunkName: "component-carousel" */ '@/components/Carousel'),
  CCarouselTeasers: () => import(/* webpackChunkName: "component-carouselteasers" */ '@/components/CarouselTeasers'),

  CFlyout: () => import(/* webpackChunkName: "component-flyout" */ '@/components/Flyout'),
  CFlyoutItem: () => import(/* webpackChunkName: "component-flyout" */ '@/components/FlyoutItem'),
  CFlyoutDivider: () => import(/* webpackChunkName: "component-flyout" */ '@/components/FlyoutDivider'),

  CExpander: () => import(/* webpackChunkName: "component-expander" */ '@/components/Expander'),
  CExpanderCard: () => import(/* webpackChunkName: "component-expandercard" */ '@/components/ExpanderCard'),

  CAccordion: () => import(/* webpackChunkName: "component-accordion" */ '@/components/Accordion'),
  CAccordionToggle: () => import(/* webpackChunkName: "component-accordion" */ '@/components/AccordionToggle'),
  CAccordionContent: () => import(/* webpackChunkName: "component-accordion" */ '@/components/AccordionContent'),

  CGrid: () => import(/* webpackChunkName: "component-grid" */ '@/components/Grid'),
  CGridColumns: () => import(/* webpackChunkName: "component-gridcolumns" */ '@/components/GridColumns'),
  CGridAside: () => import(/* webpackChunkName: "component-gridaside" */ '@/components/GridAside'),

  CMasonryGrid: () => import(/* webpackChunkName: "component-masonrygrid" */ '@/components/MasonryGrid'),

  CColumns: () => import(/* webpackChunkName: "component-columns" */ '@/components/Columns'),

  CTabsNavigation: () => import(/* webpackChunkName: "component-tabsnavigation" */ '@/components/TabsNavigation'),
  CTabsNavigationItem: () => import(/* webpackChunkName: "component-tabsnavigation" */ '@/components/TabsNavigationItem'),

  CArticleData: () => import(/* webpackChunkName: "component-article" */ '@/components/ArticleData'),
  CArticleAction: () => import(/* webpackChunkName: "component-article" */ '@/components/ArticleAction'),
  CArticleFooter: () => import(/* webpackChunkName: "component-article" */ '@/components/ArticleFooter'),
  CArticleMetaItem: () => import(/* webpackChunkName: "component-article" */ '@/components/ArticleMetaItem'),
  CLotCompareTable: () => import(/* webpackChunkName: "component-article" */ '@/components/LotCompareTable'),

  CBrands: () => import(/* webpackChunkName: "component-brands" */ '@/components/Brands'),

  CBasket: () => import(/* webpackChunkName "basket" */ '@/components/authenticated/Order/Basket'),
  CPackShotListDownloader: () => import(/* webpackChunkName "packshotdownloader" */ '@/components/authenticated/PackShotList/PackShotListDownloader'),
  CWishListCart: () => import(/* webpackChunkName "wishlistcart" */ '@/components/authenticated/WishList/WishListCart'),

  CEmployeeGrid: () => import(/* webpackChunkName: "component-employees" */ '@/components/EmployeeGrid'),
  CEmployee: () => import(/* webpackChunkName: "component-employees" */ '@/components/Employee'),

  CTeaser: () => import(/* webpackChunkName: "component-teaser" */ '@/components/Teaser'),

  CThemecardTeaser: () => import(/* webpackChunkName: "component-themecards" */ '@/components/ThemecardTeaser'),
  COffercardTeaser: () => import(/* webpackChunkName: "component-themecards" */ '@/components/OffercardTeaser'),
  COfferData: () => import(/* webpackChunkName: "component-article" */ '@/components/OfferData'),
  COfferTeaser: () => import(/* webpackChunkName: "component-themecards" */ '@/components/OfferTeaser'),

  CSearch: () => import(/* webpackChunkName: "component-search" */ '@/components/Search'),
  CProductSearchTabs: () => import(/* webpackChunkName: "component-search" */ '@/components/search/ProductSearchTabs'),
  CAutoComplete: () => import(/* webpackChunkName: "component-autocomplete" */ '@/components/AutoComplete'),

  CProduct: () => import(/* webpackChunkName: "component-product" */ '@/components/Product'),
  CProductFaqs: () => import(/* webpackChunkName: "component-product" */ '@/components/ProductFaqs'),
  CProductPrice: () => import(/* webpackChunkName: "component-product" */ '@/components/ProductPrice'),
  CProductBasketbutton: () => import(/* webpackChunkName: "component-product" */ '@/components/ProductBasketbutton'),
  CCalendarWeekSelector: () => import(/* webpackChunkName: "component-product" */ '@/components/CalendarWeekSelector'),
  CPackshotBasketButton: () => import(/* webpackChunkName: "component-packshot" */ '@/components/PackshotBasketButton'),
  CWishListButton: () => import(/* webpackChunkName: "component-wishlist" */ '@/components/WishListButton'),
  CPackShotDownloadButton: () => import(/* webpackChunkName: "component-packshot-download" */ '@/components/PackShotDownloadButton'),
  CPackShotAddDownloadListButton: () => import(/* webpackChunkName: "component-packshot-add-download-list" */ '@/components/PackShotAddDownloadListButton'),
  CStockStatus: () => import(/* webpackChunkName "stockstatus" */ '@/components/StockStatus'),
  CCheckoutNavigation: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutNavigation'),
  CCheckoutStep: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutStep'),
  CCheckoutOptionForm: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutOptionForm'),
  CCheckoutUserAddress: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutUserAddress'),
  CCheckoutUserAddresses: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutUserAddresses'),
  CCheckoutLocationAddress: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutLocationAddress'),
  CCheckoutNewUserAddresses: () => import(/* webpackChunkName "checkout" */ '@/components/authenticated/Order/CheckoutNewUserAddresses'),
  CControlValidator: () => import(/* webpackChunkName "controlvalidator" */ '@/components/form/ControlValidator'),

  COrder: () => import(/* webpackChunkName "order" */ '@/components/authenticated/Order/Order'),

  CEducation: () => import(/* webpackChunkName: "component-education" */ '@/components/Education'),

  CRegistration: () => import(/* webpackChunkName: "component-registration" */ '@/components/registration/Index'),

  // Private Components
  CUserDetail: () => import(/* webpackChunkName: "component-user" */ '@/components/authenticated/Users/Detail'),
  CUserServices: () => import(/* webpackChunkName: "component-user-services" */ '@/components/authenticated/Users/Services'),
  CUserEducations: () => import(/* webpackChunkName: "component-user-educations" */ '@/components/authenticated/Users/Educations'),
  CLocationDeliveryAddress: () => import(/* webpackChunkName: "component-location" */ '@/components/authenticated/Locations/DeliveryAddress'),
  CLocationDetail: () => import(/* webpackChunkName: "component-location" */ '@/components/authenticated/Locations/Detail'),
  CLocationEdit: () => import(/* webpackChunkName: "component-location-edit" */ '@/components/authenticated/Locations/Edit'),
  CLocationUsers: () => import(/* webpackChunkName: "component-location-users" */ '@/components/authenticated/Locations/Users'),
  CEducationRegistration: () => import(/* webpackChunkName: "component-education-registration" */ '@/components/authenticated/Education/Registration')
}

const INCLUDE_DEV_COMPONENTS = process.env.NODE_ENV === 'development' || process.env.VUE_APP_FORCE_DEV_COMPONENTS === 'true'

const DEV_COMPONENTS = {
  DevRootVariables: () => import(/* webpackChunkName: "component-dev-rootvariables" */ '@/components/dev/RootVariables'),
  DevText: () => import(/* webpackChunkName: "component-dev-text" */ '@/components/dev/Text'),
  DevColors: () => import(/* webpackChunkName: "component-dev-colors" */ '@/components/dev/Colors'),
  DevIcons: () => import(/* webpackChunkName: "component-dev-icons" */ '@/components/dev/Icons'),
  DevGrid: () => import(/* webpackChunkName: "component-dev-grid" */ '@/components/dev/Grid')
}

const DEV_COMPONENT_FALLBACKS = {
  DevRootVariables: () => ({}),
  DevText: () => ({}),
  DevColors: () => ({}),
  DevIcons: () => ({}),
  DevGrid: () => ({})
}

const ALL_COMPONENTS = Object.assign({}, INCLUDE_DEV_COMPONENTS ? DEV_COMPONENTS : DEV_COMPONENT_FALLBACKS, COMPONENTS)

Object.keys(ALL_COMPONENTS)
  .forEach(cKey => {
    Vue.component(ALL_COMPONENTS[cKey].name, ALL_COMPONENTS[cKey])
  })
