<template>
  <div class="packshot-wishlistbutton">
    <b-button v-if="!wishListItemIds.includes(productId)" class="wishlistbutton" :title="wishListButtonText" variant="custom" @click.stop="addToWishList">
      <b-icon icon="b-heart" />
    </b-button>
    <b-button v-else class="wishlistbutton" :title="wishListButtonRemoveText" variant="custom" @click.stop="removeFromWishList">
      <b-icon icon="b-heart-fill" />
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'WishListButton',
  props: {
    productId: {
      type: String,
      required: true
    },
    wishListButtonText: {
      type: String,
      default: ''
    },
    wishListButtonRemoveText: {
      type: String,
      default: ''
    }
  },
  methods: {
    addToWishList () {
      this.$store.dispatch('wishlistcart/addItem', { productId: this.productId })
    },
    removeFromWishList () {
      this.$store.dispatch('wishlistcart/removeItem', { itemId: this.productId, omitLastAction: true })
    }
  },
  computed: {
    wishListItemIds () {
      return this.$store.getters['wishlistcart/get'].containedItemExternalIds
    }
  }
}
</script>

<style lang="scss">
$wishlistbutton-padding-y: $btn-padding-y * 0.4 !default;
$wishlistbutton-padding-x: $wishlistbutton-padding-y !default;
$wishlistbutton-bg: $primary !default;
$wishlistbutton-border: transparent !default;
$wishlistbutton-font-weight: $font-weight-bold !default;
$wishlistbutton-line-height: 1.1 !default;
$wishlistbutton-hover-bg: darken($wishlistbutton-bg, 5%) !default;
$wishlistbutton-hover-border: darken($wishlistbutton-border, 5%) !default;

$wishlistbutton-text-padding-y: 0 !default;
$wishlistbutton-text-padding-x: $spacer !default;

.packshot-wishlistbutton {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;

  .wishlistbutton {
    @include button-variant($wishlistbutton-bg, $wishlistbutton-border, $wishlistbutton-hover-bg, $wishlistbutton-hover-border);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $wishlistbutton-padding-y $wishlistbutton-padding-x;
    width: 100%;
    font-weight: $wishlistbutton-font-weight;
    line-height: $wishlistbutton-line-height;
    height: 100%;
    width: 100%;
  }
}
</style>
