<template>
  <div class="tabs-navigation">
    <b-tabs @activate-tab="activateTab" nav-wrapper-class="tabs-navigation-itemlist">
      <b-tab :title="tab1Title">
        <slot v-if="activeTab == 0" name="tab1" />
      </b-tab>
      <b-tab :title="tab2Title">
        <slot v-if="activeTab == 1" name="tab2" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

export default {
  name: 'ProductSearchTabs',
  props: {
    tab1Title: {
      type: String,
      required: true
    },
    tab2Title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
    activateTab (newTabIndex) {
      this.activeTab = newTabIndex
      window.history.replaceState(
        {},
        '',
        window.location.pathname
      )
    }
  }
}
</script>
<style lang="scss">
$tabs-navigation-gap: $component-gap !default;

$tabs-navigation-itemslist-bg: $white !default;

$tabs-navigation-item-link-padding-y: 20px !default;
$tabs-navigation-item-link-padding-x: $component-padding-x !default;
$tabs-navigation-item-link-font-size: inherit !default;
$tabs-navigation-item-link-font-weight: $font-weight-bold !default;

$tabs-navigation-item-hover-link-bg: transparent !default;
$tabs-navigation-item-active-link-bg: $gray-200 !default;

$tabs-navigation-item-mobile-breakpoint: $mobile-breakpoint !default;

$tabs-navigation-item-mobile-link-padding-y: $component-padding-y * 0.6 !default;
$tabs-navigation-item-mobile-link-padding-x: $component-padding-x !default;

.tabs-navigation {
  margin-bottom: $tabs-navigation-gap;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border: none;
  }

  .tabs-navigation-itemlist {
    @include list-unstyled();
    @include make-leaf($leaf-border-radius, 'top');
    flex: 0 1 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    background-color: $tabs-navigation-itemslist-bg;

    .nav-item {
      .nav-link {
        display: block;
        padding: $tabs-navigation-item-link-padding-y $tabs-navigation-item-link-padding-x;
        font-size: $tabs-navigation-item-link-font-size;
        font-weight: $tabs-navigation-item-link-font-weight;
        text-decoration: none;
        border-color: transparent;
        color: inherit;

        &:hover {
          background-color: $tabs-navigation-item-hover-link-bg;
        }

        &.active {
          background-color: $tabs-navigation-item-active-link-bg;
        }
      }

      &:first-child {
        .nav-link {
          @include make-leaf($leaf-border-radius, 'left');
        }
      }

      @include media-breakpoint-down ($tabs-navigation-item-mobile-breakpoint) {
        .nav-link {
          padding: $tabs-navigation-item-mobile-link-padding-y $tabs-navigation-item-mobile-link-padding-x;
        }
      }
    }
  }

}
</style>
