import { LOCAL_STORAGE_KEYS } from '@/constants'

import http from '@/$plugins/http'
import Vue from 'vue'

import base from '../base'
import IS from '../base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    basket: {
      id: window.localStorage.getItem(LOCAL_STORAGE_KEYS.wishListBasket),
      itemCount: 0,
      containedItemExternalIds: [],
      lastAction: null
    },
    localConfigurations: []
  }),
  getters: base.createGetters(IS.getters, {
    get: state => state.basket,
    getId: state => window.localStorage.getItem(LOCAL_STORAGE_KEYS.wishListBasket),
    getItemCount: state => state.basket.itemCount,
    getLastAction: state => state.basket.lastAction || {},
    getConfiguration: state => state.basket.configuration || {},
    getValidationResult: state => state.basket.configurationValidationResult || {},
    getStepValidationResult: state => sectionName => ((state.basket.configurationValidationResult || {}).results || []).find(r => r.sectionName === sectionName) || { isValid: false, errors: {} },
    getSummary: state => state.basket.priceDetail || {},
    getLocalConfiguration: state => productId => state.localConfigurations.find(c => c.productId === productId)
  }),
  mutations: base.createMutations(IS.mutations, {
    set (state, { basket, merge, omitLastAction }) {
      if (omitLastAction) delete basket.lastAction
      state.basket = merge ? Object.assign(state.basket, basket) : basket
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.wishListBasket, basket.id)
    },
    setLocalConfiguration (state, configuration = {}) {
      const updateIndex = state.localConfigurations.findIndex(c => c.productId === configuration.productId)
      const newIndex = state.localConfigurations.length
      const index = updateIndex >= 0 ? updateIndex : newIndex
      const base = state.localConfigurations[index] || {}

      Vue.set(state.localConfigurations, index, Object.assign(base, configuration))
    }
  }),
  actions: base.createActions(IS.actions, {
    getMinimal ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'WishList/getMinimal'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/WishList/small${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: true })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    get ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'WishList/get'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      return new Promise((resolve, reject) => {
        http({
          method: 'get',
          url: `/order/api/WishList${getters.getId ? `/${getters.getId}` : ''}?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    update ({ state, getters, commit, dispatch, rootGetters }, basketConfiguration = {}) {
      const isKey = 'WishList/update'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/WishList/${getters.getId}?lang=${rootGetters['gui/getLanguage']}`,
          data: basketConfiguration
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    delete ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'WishList/delete'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/WishList/${getters.getId}?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    close ({ state, getters, commit, dispatch, rootGetters }) {
      const isKey = 'WishList/close'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'post',
          url: `/order/api/WishList/${getters.getId}/close?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    addItem ({ state, getters, commit, dispatch, rootGetters }, { productId = '', omitLastAction = false }) {
      const isKey = 'WishList/addItem'
      commit('setLoading', { key: isKey, loading: true, initial: state.basket.basketState === undefined })

      const localConfiguration = state.localConfigurations.find(c => c.productId === productId) || {}

      return new Promise((resolve, reject) => {
        http({
          method: 'put',
          url: `/order/api/WishList/${getters.getId}/items/add/${productId}?lang=${rootGetters['gui/getLanguage']}`,
          data: localConfiguration
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve(getters.get)
          })
          .catch(reject)
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    deleteItem ({ state, getters, commit, dispatch, rootGetters }, { itemId = '', omitLastAction = false }) {
      const isKey = 'WishList/addItem'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/WishList/${getters.getId}/items/${itemId}/delete?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve()
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    },
    removeItem ({ state, getters, commit, dispatch, rootGetters }, { itemId = '', omitLastAction = false }) {
      const isKey = 'WishList/addItem'
      commit('setSending', { key: isKey, sending: true })

      return new Promise((resolve, reject) => {
        http({
          method: 'delete',
          url: `/order/api/WishList/${getters.getId}/items/${itemId}/remove?lang=${rootGetters['gui/getLanguage']}`
        })
          .then(response => {
            commit('set', { basket: response.data.result, merge: false, omitLastAction })
            resolve()
          })
          .catch(reject)
          .finally(() => {
            commit('setSending', { key: isKey, sending: false })
          })
      })
    }
  })
}
