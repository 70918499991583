<template>
  <nav class="mainnavigation">
    <div class="mainnavigation-logo">
      <slot name="logo" />
    </div>

    <div :class="['mainnavigation-menu', { 'is-expanded': menu.isExpandable && menu.state }]">
      <b-button variant="link" class="menu-toggle" ref="menuToggle" @click="menuToggle">
        <div class="toggle-burger">
          <div class="burger-patty"></div>
          <div class="burger-patty"></div>
        </div>
      </b-button>

      <transition :name="menu.transition">
        <navigation-main-level v-if="!menu.isExpandable || (menu.isExpandable && menu.state)">
          <slot name="menu" />

          <template #metanavigation>
            <slot name="mobile-metanavigation" />
          </template>
        </navigation-main-level>
      </transition>
    </div>

    <slot name="wish-list" />
    <slot name="pack-shot-list" />
    <slot name="basket" />

    <div v-if="!hideSearch" class="mainnavigation-search">
      <b-button class="search-toggle" variant="link" @click="searchToggle"><b-icon icon="search" /></b-button>

      <transition name="search">
        <div v-if="search.state" class="search-control">
          <header-search @close-search="closeSearch" :translations="translations" :use-i18n="true" :searchoptions="searchoptions">
            <slot>
              <b-button class="search-toggle ml-2" variant="link" @click="searchToggle"><b-icon icon="close" /></b-button>
            </slot>
            <template #showmoreproducts="slotProps">
              <slot name="searchshowmoreproducts" v-bind="{ queryString: slotProps.queryString }" />
            </template>
            <template #showmoremarketingproducts="slotProps">
              <slot name="searchshowmoremarketingproducts" v-bind="{ queryString: slotProps.queryString }" />
            </template>
            <template #showmorecontent="slotProps">
              <slot name="searchshowmorecontent" v-bind="{ queryString: slotProps.queryString }" />
            </template>
            <template #showmoreeducation="slotProps">
              <slot name="searchshowmoreeducation" v-bind="{ queryString: slotProps.queryString }" />
            </template>
          </header-search>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
import NavigationMainLevel from '@/components/layout/NavigationMainLevel'

export default {
  name: 'MainNavigation',
  components: {
    NavigationMainLevel
  },
  props: {
    hideSearch: {
      type: Boolean,
      default: false
    },
    searchoptions: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      menu: {
        isExpandable: false,
        state: false,
        transition: null,
        transitionTimeout: null
      },
      search: {
        state: false,
        value: ''
      }
    }
  },
  methods: {
    menuSetExpandable () {
      this.menu.transition = ''
      this.menu.isExpandable = this.$refs.menuToggle && this.$refs.menuToggle.offsetParent !== null

      if (this.menu.transitionTimeout) clearTimeout(this.menu.transitionTimeout)
      this.menu.transitionTimeout = setTimeout(() => { this.menu.transition = 'navigation' }, 500)
    },
    menuToggle (e, state) {
      this.menu.state = state !== undefined ? state : !this.menu.state
      this.$root.$emit('scrollbar:toggle', { key: this.$options.name, state: this.menu.state, till: this.$root.mobile.down.pixel })
    },
    searchToggle (e, state) {
      this.search.state = state !== undefined ? state : !this.search.state
    },
    closeSearch () {
      this.search.state = false
    },
    onEscape (e) {
      if (e.key === 'Escape') {
        this.menuToggle(e, false)
        this.searchToggle(e, false)
      }
    }
  },
  created () {
    window.addEventListener('resize', this.menuSetExpandable)
    window.addEventListener('keydown', this.onEscape)
  },
  mounted () {
    this.$nextTick(this.menuSetExpandable)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.menuSetExpandable)
    window.removeEventListener('keydown', this.onEscape)
  }
}
</script>

<style lang="scss">
// .hover and .active state for desktop viewport is managed in NavigationMainLevelItem.vue

// desktop
$mainnavigation-padding-y: 0 !default;
$mainnavigation-padding-x: $component-padding-x !default;
$mainnavigation-inner-gap: $spacer !default;
$mainnavigation-height: 90px !default;

$mainnavigation-logo-width: 110px !default;

$mainnavigation-search-control-bg: $body-bg !default;
$mainnavigation-search-control-transition-in-duration: 300ms !default;
$mainnavigation-search-control-transition-in: all cubic-bezier(0.35, 0.05, 0.4, 1.25) $mainnavigation-search-control-transition-in-duration !default;
$mainnavigation-search-control-transition-out-duration: 200ms !default;
$mainnavigation-search-control-transition-out: all cubic-bezier(0.35, 0.05, 0.4, 1) $mainnavigation-search-control-transition-out-duration !default;

// mobile
$mainnavigation-mobile-breakpoint: $mobile-breakpoint !default;

$mainnavigation-mobile-padding-y: 0 !default;
$mainnavigation-mobile-padding-x: 0 !default;
$mainnavigation-mobile-inner-gap: 0 !default;
$mainnavigation-mobile-height: 50px !default;

$mainnavigation-mobile-logo-width: 72px !default;

$mainnavigation-mobile-toggle-burger-size: $spacer !default;
$mainnavigation-mobile-toggle-burger-patty-gap: 6px !default;
$mainnavigation-mobile-toggle-burger-patty-height: 2px !default;
$mainnavigation-mobile-toggle-burger-patty-bg: $primary !default;
$mainnavigation-mobile-toggle-burger-patty-border-radius: $mainnavigation-mobile-toggle-burger-patty-height !default;
$mainnavigation-mobile-toggle-burger-patty-transition-in: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 500ms !default;
$mainnavigation-mobile-toggle-burger-patty-transition-out: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

$mainnavigation-mobile-navigation-transition-in-duration: 400ms !default;
$mainnavigation-mobile-navigation-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1) $mainnavigation-mobile-navigation-transition-in-duration, opacity ease-in ($mainnavigation-mobile-navigation-transition-in-duration - 50ms) 50ms !default;
$mainnavigation-mobile-navigation-transition-out-duration: 250ms !default;
$mainnavigation-mobile-navigation-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1) $mainnavigation-mobile-navigation-transition-out-duration, opacity ease-out ($mainnavigation-mobile-navigation-transition-out-duration - 50ms) !default;

.mainnavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: $mainnavigation-inner-gap * -1;
  margin-right: $mainnavigation-inner-gap * -1;
  padding: $mainnavigation-padding-y $mainnavigation-padding-x;
  height: $mainnavigation-height;
  z-index: 1;

  >* {
    padding-left: $mainnavigation-inner-gap;
    padding-right: $mainnavigation-inner-gap;

    &.header-basket {
      padding-left: 0;
    }

    &.packshot-basket {
      padding: 0;
    }
  }

  .btn {
    box-shadow: none;
  }

  .mainnavigation-logo {
    .logo {
      display: block;
      width: $mainnavigation-logo-width;

      svg,
      img {
        display: block;
        margin: 0;
        width: 100%;
        max-width: none;
        height: auto;
      }
    }
  }

  .mainnavigation-menu {
    flex: 1 0 auto;

    .menu-toggle {
      display: none;
    }
  }

  .mainnavigation-search {
    .search-control {
      position: absolute;
      top: -50%;
      transform: translateY(50%);
      bottom: 0;
      left: calc(#{$mainnavigation-padding-x} + #{$mainnavigation-inner-gap * 2} + #{$mainnavigation-logo-width});
      right: $mainnavigation-inner-gap;
      margin-top: 2px;
      margin-bottom: 2px;

      &.search-enter {
        >div {
          transform: scale(0);
        }
      }

      &.search-enter-active {
        transition: all linear $mainnavigation-search-control-transition-in-duration;

        >div {
          transition: $mainnavigation-search-control-transition-in;
        }
      }

      &.search-enter-to {
        >div {
          transform: scale(1);
        }
      }

      &.search-leave {
        >div {
          opacity: 1;
          transform: scale(1);
        }
      }

      &.search-leave-active {
        transition: all linear $mainnavigation-search-control-transition-out-duration;

        >div {
          transition: $mainnavigation-search-control-transition-out;
        }
      }

      &.search-leave-to {
        >div {
          opacity: 0;
          transform: scale(0);
        }
      }
    }
  }

  .header-basket+.mainnavigation-search {
    padding-left: 0;

    .search-toggle {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down($mainnavigation-mobile-breakpoint) {
    margin-left: $mainnavigation-mobile-inner-gap * -1;
    margin-right: $mainnavigation-mobile-inner-gap * -1;
    padding: $mainnavigation-mobile-padding-y $mainnavigation-mobile-padding-x;
    height: $mainnavigation-mobile-height;

    >* {
      padding-left: $mainnavigation-mobile-inner-gap;
      padding-right: $mainnavigation-mobile-inner-gap;
    }

    .mainnavigation-logo {
      flex: 1 0 auto;
      order: 2;

      .logo {
        margin: auto;
        width: auto;
        max-width: $mainnavigation-mobile-logo-width;
      }
    }

    .mainnavigation-menu {
      flex: 0 1 auto;
      order: 3;

      .menu-toggle {
        display: block;
        padding-right: 0;

        .toggle-burger {
          position: relative;
          width: $mainnavigation-mobile-toggle-burger-size;
          height: $mainnavigation-mobile-toggle-burger-size;

          .burger-patty {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto 0 auto auto;
            height: $mainnavigation-mobile-toggle-burger-patty-height;
            background-color: $mainnavigation-mobile-toggle-burger-patty-bg;
            border-radius: $mainnavigation-mobile-toggle-burger-patty-border-radius;
            transition: $mainnavigation-mobile-toggle-burger-patty-transition-out;

            &:nth-child(1) {
              width: 100%;
              transform: translateY(-$mainnavigation-mobile-toggle-burger-patty-gap * 0.5) rotate(0deg);
            }

            &:nth-child(2) {
              width: 60%;
              transform: translateY($mainnavigation-mobile-toggle-burger-patty-gap * 0.5) rotate(0deg);
            }
          }
        }
      }

      >.level {
        &.navigation-enter {
          opacity: 0;
          transform: translateX(-50%);
        }

        &.navigation-enter-active {
          transition: $mainnavigation-mobile-navigation-transition-in;
        }

        &.navigation-enter-to {
          opacity: 1;
          transform: translateX(0%);
        }

        &.navigation-leave {
          opacity: 1;
          transform: translateX(0%);
        }

        &.navigation-leave-active {
          transition: $mainnavigation-mobile-navigation-transition-out;
        }

        &.navigation-leave-to {
          opacity: 0;
          transform: translateX(-50%);
        }
      }

      &.is-expanded {
        .menu-toggle {
          .toggle-burger {
            .burger-patty {
              transition: $mainnavigation-mobile-toggle-burger-patty-transition-in;

              &:nth-child(1) {
                width: 100%;
                transform: rotate(-135deg);
              }

              &:nth-child(2) {
                width: 100%;
                transform: rotate(135deg);
              }
            }
          }
        }
      }
    }

    .mainnavigation-search {
      order: 1;

      .search-control {
        left: $mainnavigation-mobile-inner-gap;
        right: $mainnavigation-mobile-inner-gap;

        >div {
          padding-left: 0;
          padding-right: 0;
          transform-origin: left center;

          .input-group {
            order: 2;

            .input-group-append {
              .btn {
                &:last-child {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .header-basket+.mainnavigation-search {
      padding-left: $input-btn-padding-x * 0.5;
    }
  }
}
</style>
