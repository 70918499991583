import { name as packageName } from '@/../package.json'

export const LOCAL_STORAGE_KEYS = {
  basket: `${packageName}:BasketId`,
  packshotBasket: `${packageName}:PackShotBasketId`,
  wishListBasket: `${packageName}:WishListBasketId`
}

export const MIXIN_VIEWPORT_THROTTLE_TIME = 320

export const COMPONENT_BASKET_MODULES = {
  default: {
    name: 'shoppingcart',
    getters: {
      basket: 'get'
    },
    actions: {
      get: 'get'
    },
    isEditable: true,
    isDefault: true
  },
  checkout: {
    name: 'checkout',
    getters: {
      basket: 'getBasket'
    },
    actions: {
      get: 'initialize'
    },
    isEditable: false
  }
}

export const COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP = {
  separator: {
    type: 'SeparatorCosts',
    component: 'checkout-summary-separator'
  },
  subtotal: {
    type: 'ProductCosts',
    component: 'checkout-summary-price'
  },
  additional: {
    type: 'AdditionalCosts',
    component: 'checkout-summary-price'
  },
  subtractional: {
    type: 'SubtractionalCosts',
    component: 'checkout-summary-price',
    attrs: {
      class: 'text-success'
    }
  },
  vat: {
    type: 'VatCosts',
    component: 'checkout-summary-price'
  },
  total: {
    type: 'TotalCosts',
    component: 'checkout-summary-price',
    attrs: {
      total: true
    }
  }
}

export const COMPONENT_HEADERBASKET_POPOVER_HIDE_TIMEOUT = 5000
export const COMPONENT_HEADERBUYLIST_POPOVER_HIDE_TIMEOUT = COMPONENT_HEADERBASKET_POPOVER_HIDE_TIMEOUT

export const COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP = Object.keys(COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP).reduce((types, tKey) => Object.assign(types, { [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey].type]: Object.assign({}, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP[tKey], { type: tKey }) }), {})

export const COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS = 6
export const COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS = {
  min: 1,
  max: 1000,
  step: 1,
  initialStep: 1
}

export const USER_ADDRESS_TYPES = {
  default: {
    type: 'Default',
    checkoutproperties: {
      key: 'newUserAddress'
    }
  },
  delivery: {
    type: 'Delivery',
    checkoutproperties: {
      key: 'newDeliveryAddress',
      useBaseKeyBoolean: 'deliveryAddressIsNewUserAddress',
      inheritKey: 'newUserAddress'
    }
  },
  invoice: {
    type: 'Invoice',
    checkoutproperties: {
      key: 'newInvoiceAddress',
      useBaseKeyBoolean: 'invoiceAddressIsDeliveryAddress',
      inheritKey: 'newDeliveryAddress'
    }
  }
}

export const PRODUCT_ASSEMBLYTYPES = {
  assembled: {
    isDefault: true,
    type: 'Assembled',
    associatedPrice: 'price'
  },
  unassembled: {
    type: 'Unassembled',
    associatedPrice: 'priceUnassembled'
  }
}

export const PRODUCT_ASSEMBLYTYPE_DEFAULT = (Object.values(PRODUCT_ASSEMBLYTYPES).find(t => t.isDefault) || Object.values(PRODUCT_ASSEMBLYTYPES)[0]).type
