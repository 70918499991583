import { SEARCH_INITIAL_VALUES } from '@/assets/js/config/client'
import { SEARCH_TYPE_OPTIONS } from '@/assets/js/config/server'

import http from '@/$plugins/http'
import Vue from 'vue'

import base from '../base'
import IS from '../base/is'

import { getSearchParameters } from '@/assets/js/helper/url'

import {
  filterControlResetter,
  filterIdGenerator,
  filterMapper,
  filterParameters,
  initialParameterSetter,
  resultMapper,
  searchParameters,
  sortIdGenerator,
  sortMapper,
  urlParameterKeySetter,
  urlParameterSetter
} from '@/assets/js/helper/search'

const SEARCH_TYPE_OPTIONS_KEYS = Object.keys(SEARCH_TYPE_OPTIONS)
const SEARCH_TYPE_OPTION_DEFAULT_KEY = SEARCH_TYPE_OPTIONS_KEYS.find(oKey => SEARCH_TYPE_OPTIONS[oKey].isDefault) || SEARCH_TYPE_OPTIONS_KEYS[0]

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    type: SEARCH_TYPE_OPTION_DEFAULT_KEY,
    endpoint: SEARCH_TYPE_OPTIONS[SEARCH_TYPE_OPTION_DEFAULT_KEY].endpoint,
    statistics: {
      page: {
        number: SEARCH_INITIAL_VALUES.page,
        size: SEARCH_INITIAL_VALUES.pageSize
      },
      total: {
        items: 0,
        pages: 0
      }
    },
    // Changed filters, sorts, and results to objects to store data per type
    filters: {}, // Changed from [] to {}
    sorts: {}, // Changed from [] to {}
    results: {} // Changed from [] to {}
  }),
  getters: base.createGetters(IS.getters, {
    statistics: state => state.statistics,
    // Updated getters to access data based on the current type
    filters: state => state.filters[state.type] || [],
    sorts: state => state.sorts[state.type] || [],
    getActiveSort: state => (state.sorts[state.type] || []).find(s => s.isActive),
    get: state => state.results[state.type] || [],
    getResult: state => resultId => (state.results[state.type] || []).find(r => r.id === resultId)
  }),
  mutations: base.createMutations(IS.mutations, {
    setSearchType (state, searchType = null) {
      if (SEARCH_TYPE_OPTIONS_KEYS.includes(searchType)) {
        state.type = searchType
        state.endpoint = SEARCH_TYPE_OPTIONS[searchType].endpoint
      }
      // Initialize filters, sorts, and results for the new type if they don't exist
      if (!state.filters[state.type]) {
        Vue.set(state.filters, state.type, [])
      }
      if (!state.sorts[state.type]) {
        Vue.set(state.sorts, state.type, [])
      }
      if (!state.results[state.type]) {
        Vue.set(state.results, state.type, [])
      }
    },
    setStatistics (state, statistics = {}) {
      state.statistics = {
        page: {
          number: statistics.pageNumber,
          size: statistics.pageSize
        },
        total: {
          items: statistics.totalItems,
          pages: statistics.totalPages
        }
      }
    },
    setFilters (state, filters = []) {
      const currentType = state.type
      if (!state.filters[currentType]) {
        Vue.set(state.filters, currentType, [])
      }
      filters.forEach(filter => {
        const FILTER_ID = filterIdGenerator(filter)
        const updateIndex = state.filters[currentType].findIndex(f => f.id === FILTER_ID)
        const newIndex = state.filters[currentType].length
        const existingFilter = state.filters[currentType][updateIndex]

        Vue.set(
          state.filters[currentType],
          updateIndex >= 0 ? updateIndex : newIndex,
          filterMapper(filter, existingFilter)
        )
      })

      state.filters[currentType].sort((a, b) => a.sortOrder - b.sortOrder)
    },
    setSorts (state, sorts = []) {
      const currentType = state.type
      if (!state.sorts[currentType]) {
        Vue.set(state.sorts, currentType, [])
      }
      sorts.forEach(sort => {
        const SORT_ID = sortIdGenerator(sort)
        const updateIndex = state.sorts[currentType].findIndex(s => s.id === SORT_ID)
        const newIndex = state.sorts[currentType].length
        const existingSort = state.sorts[currentType][updateIndex] || {}

        Vue.set(
          state.sorts[currentType],
          updateIndex >= 0 ? updateIndex : newIndex,
          sortMapper(sort, existingSort)
        )
      })

      state.sorts[currentType].sort((a, b) => a.sortOrder - b.sortOrder)
    },
    set (state, results = []) {
      const currentType = state.type
      Vue.set(state.results, currentType, resultMapper(results, currentType))
    },
    append (state, results = []) {
      const currentType = state.type
      if (!state.results[currentType]) {
        Vue.set(state.results, currentType, [])
      }
      state.results[currentType] = state.results[currentType].concat(resultMapper(results, currentType))
    }
  }),
  actions: base.createActions(IS.actions, {
    init ({ state, getters, commit, dispatch }, { searchType = SEARCH_TYPE_OPTION_DEFAULT_KEY, filters = [] }) {
      commit('setSearchType', searchType)
      initialParameterSetter(filters)
      return dispatch('searchByFilters')
    },
    searchByFilters ({ state, getters, commit, dispatch, rootGetters }, options = { append: false }) {
      const isKey = `${state.type}/searchByFilters`
      const append = options.append !== undefined ? options.append : false
      const currentType = state.type

      const SEARCH_PARAMETERS = searchParameters({
        filters: state.filters[currentType] || [],
        sorts: state.sorts[currentType] || [],
        statistics: state.statistics
      })
      const urlParameters = getSearchParameters()
      const querySearch = urlParameters.find(p => p.key === 'q')?.value
      state.query = querySearch ?? null

      const o = base.getOptions(options, {
        language: rootGetters['gui/getLanguage'],
        query: state.query,
        filters: SEARCH_PARAMETERS.filters,
        sort: SEARCH_PARAMETERS.sorts,
        page: SEARCH_PARAMETERS.page.number,
        pageSize: SEARCH_PARAMETERS.page.size
      })

      commit('setLoading', { key: isKey, loading: true, initial: true })

      return new Promise(resolve => {
        http({
          method: 'post',
          url: `search/api/${state.endpoint}/SearchByFilters`,
          data: o.data
        })
          .then(response => {
            urlParameterKeySetter(response.data.filters)

            commit('setStatistics', response.data)
            commit('setFilters', response.data.filters)
            commit('setSorts', response.data.sorts)
            commit(append ? 'append' : 'set', response.data.result)
            resolve(getters.get)

            urlParameterSetter(filterParameters(state.filters[currentType] || []))
          })
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    searchByFiltersAll ({ state, getters, commit, dispatch, rootGetters }, options = { append: false }) {
      const isKey = `${state.type}/searchByFilters`
      const currentType = state.type

      const SEARCH_PARAMETERS = searchParameters({
        filters: state.filters[currentType] || [],
        sorts: state.sorts[currentType] || [],
        statistics: state.statistics
      })
      const urlParameters = getSearchParameters()
      const querySearch = urlParameters.find(p => p.key === 'q')?.value
      state.query = querySearch ?? null

      const o = base.getOptions(options, {
        language: rootGetters['gui/getLanguage'],
        query: state.query,
        filters: SEARCH_PARAMETERS.filters,
        sort: SEARCH_PARAMETERS.sorts,
        page: SEARCH_PARAMETERS.page.number,
        pageSize: SEARCH_PARAMETERS.page.size
      })

      commit('setLoading', { key: isKey, loading: true, initial: true })

      return new Promise(resolve => {
        http({
          method: 'post',
          url: `search/api/${state.endpoint}/SearchByFiltersAll`,
          data: o.data
        })
          .then(response => {
            resolve(response)
          })
          .finally(() => {
            commit('setLoading', { key: isKey, loading: false })
          })
      })
    },
    setFilters ({ state, getters, commit, dispatch }, options = { action: undefined }) {
      dispatch('resetPage', { action: false })
      if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters')
    },
    setActiveSort ({ state, getters, commit, dispatch }, options = { sortId: null, action: undefined }) {
      const currentType = state.type
      const oldActiveSort = (state.sorts[currentType] || []).find(s => s.isActive) || {}
      const newActiveSort = (state.sorts[currentType] || []).find(s => s.id === options.sortId) || {}

      if (newActiveSort.id && newActiveSort !== oldActiveSort) {
        oldActiveSort.isActive = false
        newActiveSort.isActive = true
        dispatch('resetPage', { action: false })
      }

      if (options.action !== false) dispatch(options.action === undefined ? 'searchByFilters' : options.action)
    },
    getNextSearchPage ({ state, getters, commit, dispatch }, options = { action: undefined }) {
      state.statistics.page.number += 1
      if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters', { append: true })
    },
    resetFilters ({ state, getters, commit, dispatch }, options = { action: undefined }) {
      const currentType = state.type
      if (state.filters[currentType]) {
        state.filters[currentType].map(filter => Object.assign(filter, { controls: filterControlResetter(filter) }))
      }
      dispatch('resetPage', { action: false })

      if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters')
    },
    resetSorts ({ state, getters, commit, dispatch }, options = { action: undefined }) {
      const currentType = state.type
      if (state.sorts[currentType]) {
        state.sorts[currentType].map(sort => Object.assign(sort, { isActive: sort.isDefault }))
      }
      dispatch('resetPage', { action: false })

      if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters')
    },
    resetPage ({ state, getters, commit, dispatch }, options = { action: undefined }) {
      state.statistics.page.number = SEARCH_INITIAL_VALUES.page

      if (options.action !== false) dispatch(options.action !== undefined ? options.action : 'searchByFilters')
    }
  })
}
