<template>
  <div class="d-flex align-items-center">
    <div class="status-circle mr-2" :class="getStatus(stockAmount).color"></div>
    {{ getStatus(stockAmount).text }}
  </div>
</template>

<script>

export default {
  props: {
    stockAmount: {
      type: Number,
      required: true
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getStatus (stockAmount) {
      if (stockAmount > 0) {
        return { color: 'green', text: this.showText ? this.$T('availableText') : '' }
      }
      return { color: 'red', text: this.showText ? this.$T('unavailableText') : '' }
    }
  }
}
</script>
<style lang="scss">
.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.red {
    background-color: $red;
  }

  &.green {
    background-color: $primary;
  }
}
</style>
