<template>
  <div class="teaser-outer">
    <b-card :class="['teaser', `img-type-${imgType}`]" no-body @click="onClick">
      <template v-if="!noImg">
        <a ref="link" :href="href">

          <b-card-img v-if="imgSrc" :src="imgSrc" :alt="imgAlt">test</b-card-img>
          <div v-else class="card-img">
            <c-placeholder-image ratio="4x3" :size="imgType === 'product' ? 100 : 75" />
          </div>
        </a>
      </template>

      <template #header v-if="tagText">
        <b-badge :variant="tagVariant">{{ tagText }}</b-badge>
      </template>

      <b-card-body>
        <timeago v-if="datetime !== null" class="teaser-datetime" :datetime="datetime" :converterOptions="{ format: 'PPP', forceDateFormat: true }" auto-update />
        <slot />
      </b-card-body>
      <template #footer>
        <slot name="teaserfooter" />
        <b-form-row v-if="!hideDefaultFooter" class="align-items-end">
          <b-col>
            <div v-if="languageText" class="teaser-tag">{{ languageText }}</div>
            <div class="mt-2">
              <b-badge v-if="stateText" class="teaser-state badge-outline" pill :variant="stateVariant">{{ stateText }}</b-badge>
            </div>
          </b-col>

          <b-col>
            <a class="teaser-arrow">
              <b-icon icon="arrow-right" scale="2.5" />
            </a>
          </b-col>
        </b-form-row>
      </template>
    </b-card>
  </div>
</template>

<script>
import { TEASER_IMAGE_TYPES } from '@/assets/js/config/client'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const TAG_VARIANTS = ['default'].concat(Object.keys(SCSS_VARIABLES.colors.theme))

export default {
  name: 'Teaser',
  props: {
    href: {
      type: String,
      required: true
    },
    noImg: {
      type: Boolean,
      default: false
    },
    imgType: {
      type: String,
      default: 'default',
      validator: value => TEASER_IMAGE_TYPES.includes(value)
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    },
    tagText: {
      type: String,
      default: ''
    },
    tagVariant: {
      type: String,
      default: 'default',
      validator: value => TAG_VARIANTS.includes(value)
    },
    languageText: {
      type: String,
      defualt: null
    },
    stateText: {
      type: String,
      default: ''
    },
    stateVariant: {
      type: String,
      default: 'default',
      validator: value => TAG_VARIANTS.includes(value)
    },
    datetime: {
      type: String,
      default: null
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick (e) {
      if (e.target !== this.$refs.link) this.$refs.link.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey }))
    }
  }
}
</script>

<style lang="scss">
$teaser-padding-y: $component-padding-y * 0.6 !default;
$teaser-padding-x: $component-padding-x !default;
$teaser-font-size: $font-size-base * 0.875 !default;

$teaser-datetime-margin-bottom: $spacer * 0.5 !default;
$teaser-datetime-color: $gray-500 !default;

$teaser-img-product-padding-y: $component-padding-x / 4 * 3 !default;
$teaser-img-product-padding-x: $component-padding-x !default;

$teaser-img-transition-in: transform ease-out 200ms !default;
$teaser-img-transition-out: transform ease-out 200ms !default;

$teaser-arrow-animation: h-bounce 400ms ease-out 1;

.teaser-outer {
  height: 100%;
}

.teaser {
  position: relative;
  overflow: hidden;
  height: 100%;
  font-size: $teaser-font-size;
  cursor: pointer;

  .card-img {
    transform-origin: bottom center;
    transform: scale(1);
    transition: $teaser-img-transition-out;
  }

  .card-header {
    padding: $teaser-padding-y $teaser-padding-x 0;
  }

  .card-body {
    padding: $teaser-padding-y $teaser-padding-x;
  }

  .card-footer {
    padding: 0 $teaser-padding-x $teaser-padding-y;
  }

  .card-header,
  .card-body,
  .card-footer {
    >* {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .teaser-tag {
    font-weight: $font-weight-base;
    font-size: $font-size-sm * 0.85;
  }

  .teaser-datetime {
    display: block;
    margin-bottom: $teaser-datetime-margin-bottom;
    color: $teaser-datetime-color;
  }

  .teaser-state {
    font-size: $font-size-sm * 0.75;
  }

  .teaser-arrow {
    display: block;
    text-align: right;

    .bi {
      width: 2em;
    }
  }

  &.img-type-product,
  &.img-type-packshot,
  &.img-type-marketingproduct {
    .card-img {
      margin: ($teaser-img-product-padding-y / 3 * 4) $teaser-img-product-padding-x (
        $teaser-img-product-padding-y / 3 * 2
      );
    width: calc(100% - (#{$teaser-img-product-padding-x} * 2));
    border-radius: 0;
  }
}

&:hover {
  .card-img {
    transform: scale(1.05);
    transition: $teaser-img-transition-in;
  }

  .teaser-arrow {
    animation: $teaser-arrow-animation;
  }
}
}
</style>
