<template>
  <div class="offercard-teaser">
    <div v-if="!imgNone" class="offercard-img ratio ratio-1x1">
      <div v-if="imgSrc">
        <img :src="imgSrc" />
        <span class="sr-only">{{ imgAlt }}</span>
      </div>
      <c-placeholder-image v-else size="75" />
      <a class="stretched-link" :href="href"></a>
    </div>
    <div class="offercard-content">
      <div class="offercard-header">
        <slot name="header" />
      </div>

      <div class="offercard-body">
        <slot name="body" />
      </div>

      <div class="offercard-prices">
        <slot name="prices" />
      </div>

      <div class="offercard-footer">
        <slot name="basketbutton" />
      </div>
    </div>
  </div>
</template>

<script>
import { BREAKPOINTS } from '@/assets/js/modules/scss-variables'

export default {
  name: 'OffercardTeaser',
  props: {
    href: {
      type: String,
      required: true
    },
    imgNone: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      BREAKPOINTS
    }
  }
}
</script>

<style lang="scss">
$offercard-teaser-gap: $grid-gutter-width !default;
$offercard-teaser-padding-y: $component-padding-y * 0.6 !default;
$offercard-teaser-padding-x: $component-padding-x * 0.5 !default;
$offercard-teaser-bg: $card-bg !default;
$offercard-teaser-font-size: $font-size-base !default;

$offercard-teaser-img-width: 31% !default;
$offercard-teaser-img-transition-in: transform ease-out 200ms !default;
$offercard-teaser-img-transition-out: transform ease-out 200ms !default;

$offercard-teaser-product-padding-y: 0 !default;
$offercard-teaser-product-padding-x: $spacer * 0.5 !default;
$offercard-teaser-product-font-size: $font-size-base * 0.75 !default;
$offercard-teaser-product-any-gap: $spacer * 0.5 !default;

$offercard-teaser-product-dots-gap: $spacer * 0.5 !default;

$offercard-teaser-actionbutton-animation: h-bounce 400ms ease-out 1 !default;

$offercard-teaser-img-lg-width: 34% !default;

$offercard-teaser-img-md-width: 36% !default;

$offercard-teaser-img-sm-width: 100% !default;

$offercard-teaser-img-product-padding-y: $component-padding-x / 4 * 3 !default;
$offercard-teaser-img-product-padding-x: $component-padding-x !default;

.offercard-teaser {
  @include make-leaf($leaf-border-radius-lg);
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: $offercard-teaser-gap;
  background-color: $offercard-teaser-bg;
  font-size: $offercard-teaser-font-size;

  &:last-child {
    margin-bottom: 0;
  }

  .offercard-img {
    @include make-leaf($leaf-border-radius-lg, 'top');
    //width: $offercard-teaser-img-width;
    margin: ($offercard-teaser-img-product-padding-y / 3 * 4) $offercard-teaser-img-product-padding-x (
      $offercard-teaser-img-product-padding-y / 3 * 2
    );
  width: calc(50% - (#{$offercard-teaser-img-product-padding-x} * 2));
  border-radius: 0;
  cursor: pointer;

  div {
    background: no-repeat center center;
    background-size: contain;
    transform-origin: center center;
    transform: scale(1);
    transition: $offercard-teaser-img-transition-out;
  }
}

.offercard-content {
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .offercard-header {
    overflow: hidden;
    padding: $offercard-teaser-padding-y $offercard-teaser-padding-x 0;
  }

  .offercard-body {
    padding: ($offercard-teaser-padding-y * 0.5) $offercard-teaser-padding-x;
  }

  .offercard-prices {
    padding: ($offercard-teaser-padding-y * 0.5) $offercard-teaser-padding-x;
  }

  .offercard-footer {
    padding: 0 $offercard-teaser-padding-x $offercard-teaser-padding-y;
  }

  .offercard-header,
  .offercard-body,
  .offercard-footer {
    @include clearfix();

    >* {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.offercard-title {}

.offercard-products {
  margin: 0;

  .slick-list {
    @include reset-leaf();
  }

  .slick-slide {
    [tabindex="-1"] {
      padding: $offercard-teaser-product-padding-y $offercard-teaser-product-padding-x;
      font-size: $offercard-teaser-product-font-size;

      * {
        +* {
          margin-top: $offercard-teaser-product-any-gap;
        }
      }

      a {
        display: block;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .slick-dots {
    margin-top: $offercard-teaser-product-dots-gap;
    margin-bottom: 0;
  }
}

.offercard-product-count {
  line-height: 1;
}

.offercard-actionbutton {
  display: block;
  float: right;
}

&:hover {
  .offercard-img {
    >div {
      transform: scale(1.05);
      transition: $offercard-teaser-img-transition-in;
    }
  }

  .offercard-actionbutton {
    animation: $offercard-teaser-actionbutton-animation;
  }
}

@include media-breakpoint-down(lg) {
  .offercard-img {
    width: $offercard-teaser-img-lg-width;
  }
}

@include media-breakpoint-down(md) {
  .offercard-img {
    width: $offercard-teaser-img-md-width;
  }
}

@include media-breakpoint-down(sm) {
  flex-direction: column;

  .offercard-img {
    width: $offercard-teaser-img-sm-width;
    transform-origin: bottom center;
  }

  .offercard-content {
    .offercard-body {
      padding-top: $offercard-teaser-padding-y;
      padding-bottom: $offercard-teaser-padding-y;
    }
  }

  .offercard-products {
    .slick-slide {
      [tabindex="-1"] {
        p {
          overflow: visible;
          white-space: normal;
        }
      }
    }
  }
}
}
</style>
