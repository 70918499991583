<template>
  <section class="offerdata">
    <b-row>
      <b-col :[$root.mobile.up.key]="3">
        <b-badge pill class="offerdata-title" :variant="titleVariant">
          <slot name="title" />
        </b-badge>
      </b-col>

      <b-col>
        <div class="offerdata-header">
          <slot name="header" />
        </div>
        <div class="offerdata-content">
          <slot name="content" />
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const VARIANTS = [null, ''].concat(Object.keys(SCSS_VARIABLES.colors.theme))

export default {
  name: 'Offerdata',
  props: {
    wrapper: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: null
    },
    titleVariant: {
      type: String,
      default: null,
      validator: value => VARIANTS.includes(value)
    }
  },
  data () {
    return {
      minHeight: 100,
      expanded: this.expandable ? false : null
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss">
$offerdata-gap: $component-gap * 0.5 !default;
$offerdata-padding-y: $offerdata-gap !default;
$offerdata-padding-x: 0 !default;
$offerdata-border: $border-width $border-style $border-color !default;
$offerdata-font-size: $font-size-sm !default;

$offerdata-title-gap: 0 !default;
$offerdata-title-font-size: inherit !default;

$offerdata-item-gap: $offerdata-font-size !default;
$offerdata-item-title-gap: $offerdata-gap !default;
$offerdata-item-separated-border: $offerdata-border !default;

$offerdata-content-box-shadow-spread: $spacer !default;
$offerdata-content-box-shadow: inset 0 ($offerdata-content-box-shadow-spread * -0.75) $offerdata-content-box-shadow-spread (
  $offerdata-content-box-shadow-spread * 0.25) $white !default;
$offerdata-content-transition-in: all ease-out 400ms !default;
$offerdata-content-transition-out: all ease-out 300ms !default;

$offerdata-expander-gap: $offerdata-title-gap * 0.5 !default;
$offerdata-expander-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 500ms !default;
$offerdata-expander-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

$offerdata-mobile-breakpoint: $mobile-breakpoint !default;

$offerdata-mobile-title-gap: $spacer !default;

.offerdata {
  position: relative;
  margin-top: $offerdata-gap;
  margin-bottom: $offerdata-gap;
  padding: $offerdata-padding-y $offerdata-padding-x 0;
  border-top: $offerdata-border;
  font-size: $offerdata-font-size;

  .offerdata-title {
    margin-bottom: $offerdata-title-gap;
    font-size: $offerdata-title-font-size;
    text-align: left;
    white-space: normal;

    >* {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      color: inherit;
      line-height: inherit;
    }
  }

  .offerdata-header {
    div {
      font-size: $font-size-base
    }
  }

  &:not(.is-wrapper) {
    .offerdata-content {
      dl {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        >dt,
        >dd {
          flex: 0 0 50%;
          max-width: 50%;
          margin: 0;
          padding-top: $offerdata-item-gap;
          text-overflow: ellipsis;
          overflow: hidden;

          .subject-information {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        >dt:first-child {
          padding-top: 0;

          +dd {
            padding-top: 0;
          }
        }

        >dt {
          padding-right: $offerdata-item-gap;
        }
      }

      table {
        table-layout: fixed;
        width: 100%;

        thead,
        tbody,
        tfoot {
          >tr {

            >th,
            >td {
              padding-top: $offerdata-item-gap;
            }
          }

          &:first-child {
            >tr {
              &:first-child {

                >th,
                >td {
                  padding-top: 0;
                }
              }
            }
          }
        }

        thead>tr,
        tbody>tr.thead-tr,
        tfoot>tr.thead-tr {
          >th {
            padding-top: $offerdata-item-title-gap;
            padding-bottom: $offerdata-item-gap;
            border-bottom: $offerdata-item-separated-border;
          }
        }
      }

      >*:last-child {
        margin-bottom: 0;
      }

      &.expand-enter-active {
        transition: $offerdata-content-transition-in;
      }

      &.expand-leave-active {
        transition: $offerdata-content-transition-out;
      }
    }

    .offerdata-expander {
      display: block;
      float: right;
      margin-top: $offerdata-expander-gap;
      transition: $offerdata-expander-transition-out;
    }

    &.is-expandable {
      .offerdata-content {
        position: relative;
        overflow: hidden;

        &:before {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          left: $offerdata-content-box-shadow-spread * -2;
          right: $offerdata-content-box-shadow-spread * -2;
          height: 200%;
          box-shadow: $offerdata-content-box-shadow;
          transition: inherit;
        }

        &.expand-enter,
        &.expand-leave-to {
          &:before {
            transform: translateY($offerdata-content-box-shadow-spread * 0
            );
        }
      }

      &.expand-enter-to,
      &.expand-leave {
        &:before {
          transform: translateY($offerdata-content-box-shadow-spread * 2);
        }
      }

      &.expand-state-open {
        &:before {
          display: none;
        }
      }
    }

    &.is-expanded {
      .offerdata-expander {
        transform: rotate(-135deg);
        transition: $offerdata-expander-transition-in;
      }
    }
  }
}

.form-control,
.custom-select,
.custom-control,
.b-custom-control {
  font-size: $input-font-size;
}

.form-control-sm,
.custom-select-sm,
.custom-control-sm,
.b-custom-control-sm {
  font-size: $input-font-size-sm;
}

.form-control-lg,
.custom-select-lg,
.custom-control-lg,
.b-custom-control-lg {
  font-size: $input-font-size-lg;
}

@include media-breakpoint-down($offerdata-mobile-breakpoint) {
  .offerdata-title {
    margin-bottom: $offerdata-mobile-title-gap;
  }

  &:not(.is-wrapper) {
    .offerdata-content {
      dl {
        display: block;

        >dt,
        >dd {
          max-width: none;
        }

        >dd {
          padding-top: 0;
        }
      }

      table {
        display: block;

        >thead,
        >tfoot {
          display: none;
        }

        >tbody {
          display: block;

          >tr {
            display: block;

            >th,
            >td {
              display: block;
            }

            >th {
              ~td {
                padding-top: 0;

                &[data-th] {
                  &:before {
                    display: block;
                    content: attr(data-th);
                    padding-top: $offerdata-item-gap;
                    font-weight: $table-th-font-weight;
                  }

                  &:last-child {
                    margin-bottom: calc(#{$offerdata-item-title-gap} - #{$offerdata-item-gap});
                  }
                }
              }

              +td {
                &[data-th] {
                  &:before {
                    margin-top: $offerdata-item-gap;
                    border-top: $offerdata-item-separated-border;
                  }
                }
              }
            }

            &:first-child {

              >th,
              >td {
                &:first-child {
                  padding-top: 0;
                }
              }
            }

            &:last-child {
              >th {
                ~td {
                  &[data-th] {
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
}
</style>
